/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { PixiPlugin } from 'gsap/PixiPlugin';
import './src/styles/app.scss';
import { CustomEase } from './src/utils/custom-ease';

export const onClientEntry = () => {
  // without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
  gsap.registerPlugin(PixiPlugin, MotionPathPlugin, CSSPlugin, CustomEase);
};

// eslint-disable-next-line import/prefer-default-export
export const onServiceWorkerUpdateReady = () => {
  // eslint-disable-next-line no-alert
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`,
  );

  if (answer === true) {
    window.location.reload();
  }
};
